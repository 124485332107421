import { Injectable } from '@angular/core'
import { PassService } from '../../features/passes/services/pass.service'
import { ServiceService } from '../../features/services/services/service.service'
import { IncomeService } from '../../features/incomes/services/income.service'
import { UserService } from '../../features/users/services/user.service'

@Injectable({
	providedIn: 'root',
})
export class HelperService {
	private _mobile = false

	constructor(
		private usersService: UserService,
		private incomesService: IncomeService,
		private passService: PassService,
		private serviceService: ServiceService,
	) {}

	get mobile(): boolean {
		return this._mobile
	}
	set mobile(value: boolean) {
		this._mobile = value
	}

	// Reset service data
	resetServiceData() {
		console.warn('Resetting service data')
		this.passService.reset()
		this.serviceService.reset()
		this.incomesService.reset()
		this.usersService.reset()
	}
}
