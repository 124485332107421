import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable, signal } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { endpoints } from '../../../../environments/endpoints'
import { Service, ServiceCreate } from '../../../shared/model/Service'
import { NotificationService } from '../../../shared/services/notification.service'

@Injectable({
	providedIn: 'root',
})
export class ServiceService {
	services = signal<Service[]>([])
	isLoading = signal<boolean>(true)

	private _servicesLoaded = false

	constructor(private http: HttpClient, private notificationService: NotificationService) {}

	//#region Get Services
	async getServices(forceReload = false) {
		if (this._servicesLoaded && !forceReload) {
			console.warn('Services are already loaded')
			return
		}

		try {
			this.isLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.get<string>(endpoints.getServices, {
					withCredentials: true,
					observe: 'response',
				})
			)

			if (response.status === 200 && response.body !== null) {
				this.services.set(response.body as unknown as Service[])
				this._servicesLoaded = true
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt a szolgáltatások lekérdezése során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a szolgáltatások lekérdezése során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isLoading.set(false)
		}
	}
	//#endregion

	//#region Create Service
	async createService(service: ServiceCreate) {
		try {
			this.isLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.post<string>(endpoints.createService, service, {
					withCredentials: true,
					observe: 'response',
				})
			)

			if (response.status === 201) {
				this.notificationService.openSnackBar('Szolgáltatás sikeresen létrehozva')
				this.getServices(true)
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt a szolgáltatás létrehozása során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a szolgáltatás létrehozása során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isLoading.set(false)
		}
	}
	//#endregion

	//#region Update Service
	async updateService(updatedService: Service) {
		try {
			this.isLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.patch<string>(
					endpoints.updateService + updatedService.id,
					updatedService,
					{
						withCredentials: true,
						observe: 'response',
					}
				)
			)

			if (response.status === 200) {
				this.getServices(true)
				this.notificationService.openSnackBar('Szolgáltatás sikeresen frissítve')
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt a szolgáltatás frissítése során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a szolgáltatás frissítése során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isLoading.set(false)
		}
	}
	//#endregion

	//#region Delete Service
	async deleteService(serviceId: string) {
		try {
			this.isLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.delete<string>(endpoints.deleteService + serviceId, {
					withCredentials: true,
					observe: 'response',
				})
			)

			if (response.status === 200) {
				this.getServices(true)
				this.notificationService.openSnackBar('Szolgáltatás sikeresen törölve')
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt a szolgáltatás törlése során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a szolgáltatás törlése során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isLoading.set(false)
		}
	}
	//#endregion

	// MARK: Reset data
	reset() {
		this.services.set([])
		this._servicesLoaded = false
	}
}
