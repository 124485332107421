import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http'
import { Injectable, signal } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { endpoints } from '../../../../environments/endpoints'
import { Income, IncomeCreate } from '../../../shared/model/Income'
import { PassService } from '../../passes/services/pass.service'
import { NotificationService } from '../../../shared/services/notification.service'

// MARK: IncomePropType
type IncomePropType = {
	forceReload?: boolean
	isPaid?: string
	startDate?: Date | null
	endDate?: Date | null
	userId?: string | null
	pagination?: { pageIndex: number; pageSize: number }
}

@Injectable({
	providedIn: 'root',
})
export class IncomeService {
	incomes = signal<Income[]>([])
	totalIncome = signal<number>(0)
	isLoading = signal<boolean>(true)

	private _incomesLoaded = false

	constructor(
		private http: HttpClient,
		private passService: PassService,
		private notificationService: NotificationService
	) {}

	//#region Get
	/**
	 * Get incomes from the server. If already loaded, it will not download them again
	 * @param forceReload - If true, the incomes will be reloaded from the server
	 * @param startDate - The start date to filter incomes
	 * @param endDate - The end date to filter incomes
	 * @param userId - The user id to filter incomes
	 * @param isPaid - The paid status to filter incomes (Összes, Fizetett, Nem fizetett)
	 * @returns True if the incomes were successfully loaded, false otherwise
	 **/
	async getIncomes({ forceReload = false, isPaid = 'Összes', startDate, endDate, userId, pagination }: IncomePropType) {
		if (startDate === null || endDate === null) {
			// If the dates are selected and then clicked again, one of the dates will be null ->
			// 		only filter incomes if both dates are selected
			// These are undefined if the dates are not selected
			return
		}

		if (this._incomesLoaded && !forceReload) {
			console.warn('Incomes are already loaded')
			return
		}

		console.warn(
			`Getting incomes with forceReload: ${forceReload}, 
			startDate: ${startDate}, 
			endDate: ${endDate}, 
			userId: ${userId}, 
			isPaid: ${isPaid}, 
			pagination: ${pagination?.pageIndex} ${pagination?.pageSize}`
		)

		const params = new HttpParams()
			.set('startDate', startDate?.toISOString() ?? '')
			.set('endDate', endDate?.toISOString() ?? '')
			.set('userId', userId ?? '')
			.set('isPaid', isPaid)
			.set('pageIndex', pagination?.pageIndex.toString() ?? '')
			.set('pageSize', pagination?.pageSize.toString() ?? '')

		try {
			this.isLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.get<string>(endpoints.getIncomes, {
					withCredentials: true,
					observe: 'response',
					params: params,
				})
			)

			if (response.status === 200 && response.body !== null) {
				this.incomes.set((response.body as any).incomes as Income[])
				this.totalIncome.set((response.body as any).totalIncomes as number)
				this._incomesLoaded = true
				return true
			} else {
				this.notificationService.openSnackBar(
					`Hiba történt a bevételek lekérdezése során (${response.status}): ${response.body}`
				)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a bevételek lekérdezése során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isLoading.set(false)
		}
	}
	//#endregion

	//#region Create
	async createIncome(newIncomeObject: IncomeCreate) {
		try {
			this.isLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.post<string>(endpoints.createIncome, newIncomeObject, {
					withCredentials: true,
					observe: 'response',
				})
			)

			if (response.status === 201) {
				this.getIncomes({ forceReload: true })
				this.passService.getActivePasses(true)
				this.notificationService.openSnackBar('Bevétel sikeresen létrehozva')
				return true
			} else {
				this.notificationService.openSnackBar(
					`Hiba történt a bevételek létrehozása során (${response.status}): ${response.body}`
				)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a bevételek létrehozása során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isLoading.set(false)
		}
	}
	//#endregion

	//#region Update
	async updateIncome(updatedIncome: Income) {
		try {
			this.isLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.patch<string>(endpoints.updateIncome + updatedIncome.id, updatedIncome, {
					withCredentials: true,
					observe: 'response',
				})
			)

			if (response.status === 200) {
				this.getIncomes({ forceReload: true })
				this.notificationService.openSnackBar('Bevétel sikeresen módosítva')
				return true
			} else {
				this.notificationService.openSnackBar(
					`Hiba történt a bevételek módosítása során (${response.status}): ${response.body}`
				)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a bevételek módosítása során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isLoading.set(false)
		}
	}
	//#endregion

	//#region Delete
	async deleteIncome(incomeId: string) {
		try {
			this.isLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.delete<string>(endpoints.deleteIncome + incomeId, {
					withCredentials: true,
					observe: 'response',
				})
			)

			if (response.status === 200) {
				this.getIncomes({ forceReload: true })
				this.notificationService.openSnackBar('Bevétel sikeresen törölve')
				return true
			} else {
				console.error(response)
				this.notificationService.openSnackBar(
					`Hiba történt a bevétel törlése során (${response.status}): ${response.body}`
				)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a bevétel törlése során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isLoading.set(false)
		}
	}
	//#endregion

	// MARK: Reset data
	reset() {
		this.incomes.set([])
		this.totalIncome.set(0)
		this._incomesLoaded = false
	}
}
