import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { Injectable, signal } from '@angular/core'
import { lastValueFrom, tap } from 'rxjs'
import { endpoints } from '../../../../environments/endpoints'
import { User, UserCreate, UserDeleteResponse } from '../../../shared/model/User'
import { NotificationService } from '../../../shared/services/notification.service'

@Injectable({
	providedIn: 'root',
})
export class UserService {
	users = signal<User[]>([])
	isLoading = signal<boolean>(true)

	private _usersLoaded = false

	constructor(private http: HttpClient, private notificationService: NotificationService) {}

	//#region Get Users
	async getUsers(forceReload = false) {
		if (this._usersLoaded && !forceReload) {
			console.warn('Users are already loaded')
			return
		}

		try {
			this.isLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.get<string>(endpoints.getAllUsers, {
					withCredentials: true,
					observe: 'response',
				})
			)

			console.log(response);
			if (response.status === 200 && response.body !== null) {
				this.users.set(response.body as unknown as User[])
				this._usersLoaded = true
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt a felhasználók lekérdezése során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a felhasználók lekérdezése során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isLoading.set(false)
		}
	}
	//#endregion

	//#region: Create User
	async createUser(user: UserCreate) {
		try {
			this.isLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.post<string>(endpoints.createUser, user, {
					withCredentials: true,
					observe: 'response',
				})
			)

			if (response.status === 201) {
				this.getUsers(true)
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt a felhasználó létrehozása során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a felhasználó létrehozása során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isLoading.set(false)
		}
	}
	//#endregion

	//#region: Update User
	async updateUser(updatedUser: User) {
		try {
			this.isLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.patch<string>(
					endpoints.updateUser + updatedUser.id,
					updatedUser,
					{
						withCredentials: true,
						observe: 'response',
					}
				)
			)

			if (response.status === 200) {
				this.getUsers(true)
				this.notificationService.openSnackBar('Felhasználó sikeresen módosítva')
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt a felhasználó módosítása során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a felhasználó módosítása során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isLoading.set(false)
		}
	}
	//#endregion

	//#region: Delete User
	async deleteUser(userID: string) {
		try {
			this.isLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.delete<string>(
					endpoints.deleteUser + userID, 
					{
						withCredentials: true,
						observe: 'response',
					})
			)

			if (response.status === 200) {
				this.users.set(this.users().filter((user) => user.id !== userID))
				this.notificationService.openSnackBar('Felhasználó sikeresen törölve')
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt a felhasználó törlése során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a felhasználó törlése során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isLoading.set(false)
		}
	}
	//#endregion

	// MARK: Reset data
	reset() {
		this.users.set([])
		this._usersLoaded = false
	}
}
