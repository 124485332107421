{
  "name": "elrek-system-angular",
  "version": "2.4.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.2",
    "@angular/cdk": "^19.1.0",
    "@angular/common": "^19.1.2",
    "@angular/compiler": "^19.1.2",
    "@angular/core": "^19.1.2",
    "@angular/forms": "^19.1.2",
    "@angular/material": "^19.1.0",
    "@angular/material-moment-adapter": "^19.1.0",
    "@angular/platform-browser": "^19.1.2",
    "@angular/platform-browser-dynamic": "^19.1.2",
    "@angular/router": "^19.1.2",
    "chart.js": "^4.4.4",
    "ngx-skeleton-loader": "^9.0.0",
    "rxjs": "~7.8.0",
    "schematics-scss-migrate": "^2.3.17",
    "tslib": "^2.3.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.3",
    "@angular/cli": "^19.1.3",
    "@angular/compiler-cli": "^19.1.2",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.5.3"
  }
}
