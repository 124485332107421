import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable, signal } from '@angular/core'
import { lastValueFrom } from 'rxjs'
import { endpoints } from '../../../../environments/endpoints'
import { ActivePass, ActivePassCreate } from '../../../shared/model/ActivePass'
import { Pass, PassCreate, PassTime } from '../../../shared/model/Pass'
import { NotificationService } from '../../../shared/services/notification.service'

@Injectable({
	providedIn: 'root',
})
export class PassService {
	passes = signal<Pass[]>([])
	activePasses = signal<ActivePass[]>([])
	isPassLoading = signal<boolean>(true)
	isActivePassLoading = signal<boolean>(true)

	private _passesLoaded = false
	private _activePassesLoaded = false

	constructor(private http: HttpClient, private notificationService: NotificationService) {}

	// MARK: parseDuration
	/**
	 * Parses the duration string from the DB into a PassTime object
	 * @param duration Assembled time from DB (eg: 1_week, 2_month, 3_month)
	 * @returns PassTime object
	 */
	parseDuration(duration: string): PassTime {
        const parsed = duration.split("_", 2)
        return {
            timeInterval: Number(parsed[0]),
            timeType: parsed[1]
        }
    }

	//#region Get
	async getPasses(forceReload = false) {
		if (this._passesLoaded && !forceReload) {
			console.warn('Passes are already loaded')
			return
		}

		try {
			this.isPassLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.get<string>(endpoints.getPasses, {
					withCredentials: true,
					observe: 'response',
				})
			)

			if (response.status === 200 && response.body !== null) {
				this.passes.set(response.body as unknown as Pass[])
				this._passesLoaded = true
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt a bérletek lekérdezése során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a bérletek lekérdezése során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isPassLoading.set(false)
		}
	}

	async getActivePasses(forceReload = false) {
		if (this._activePassesLoaded && !forceReload) {
			console.warn('Active Passes are already loaded')
			return
		}

		try {
			this.isActivePassLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.get<string>(endpoints.getActivePasses, {
					withCredentials: true,
					observe: 'response',
				})
			)

			if (response.status === 200 && response.body !== null) {
				this.activePasses.set(response.body as unknown as ActivePass[])
				this._activePassesLoaded = true
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt az aktív bérletek lekérdezése során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt az aktív bérletek lekérdezése során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isActivePassLoading.set(false)
		}
	}
	//#endregion

	//#region Create
	async createPass(newPass: PassCreate) {
		try {
			this.isPassLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.post<string>(endpoints.createPass, newPass, {
					withCredentials: true,
					observe: 'response',
				})
			)

			if (response.status === 201) {
				this.getPasses(true)
				this.notificationService.openSnackBar('Bérlet sikeresen létrehozva')
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt a bérlet létrehozása során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a bérlet létrehozása során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isPassLoading.set(false)
		}
	}

	async createActivePass(newActivePass: ActivePassCreate) {
		try {
			this.isActivePassLoading.set(true)			
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.post<string>(
					endpoints.createActivePass,
					newActivePass,
					{
						withCredentials: true,
						observe: 'response',
					}
				)
			)

			if (response.status === 201) {
				this.getActivePasses(true)
				this.notificationService.openSnackBar('Aktív bérlet sikeresen létrehozva')
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt az aktív bérlet létrehozása során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt az aktív bérlet létrehozása során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isActivePassLoading.set(false)
		}
	}
	//#endregion

	//#region Update
	async updatePass(updatedPass: Pass) {
		try {
			this.isPassLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.patch<string>(
					endpoints.updatePass + updatedPass.id,
					updatedPass,
					{
						withCredentials: true,
						observe: 'response',
					}
				)
			)

			if (response.status === 200) {
				this.getPasses(true)
				this.notificationService.openSnackBar('Bérlet sikeresen módosítva')
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt a bérlet módosítása során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a bérlet módosítása során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isPassLoading.set(false)
		}
	}
	//#endregion

	async updateActivePass(updatedActivePass: ActivePass) {
		try {
			this.isActivePassLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.patch<string>(
					endpoints.updateActivePass + updatedActivePass.id,
					updatedActivePass,
					{
						withCredentials: true,
						observe: 'response',
					}
				)
			)
			
			if (response.status === 200) {
				this.getPasses(true)
				this.notificationService.openSnackBar('Aktív bérlet sikeresen módosítva')
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt az aktív bérlet módosítása során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt az aktív bérlet módosítása során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isActivePassLoading.set(false)
		}
	}

	//#region: Delete
	async deletePass(passId: string) {
		try {
			this.isPassLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.delete<string>(endpoints.deletePass + passId, {
					withCredentials: true,
					observe: 'response',
				})
			)
			
			if (response.status === 200) {
				this.getPasses(true)
				this.notificationService.openSnackBar('Bérlet sikeresen törölve')
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt a bérlet törlése során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt a bérlet törlése során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isPassLoading.set(false)
		}
	}

	async deleteActivePass(activePassId: string) {
		try {
			this.isActivePassLoading.set(true)
			const response: HttpResponse<string> = await lastValueFrom(
				this.http.delete<string>(endpoints.deleteActivePass + activePassId, {
					withCredentials: true,
					observe: 'response',
				})
			)

			if (response.status === 200) {
				this.getActivePasses(true)
				this.notificationService.openSnackBar('Aktív bérlet sikeresen törölve')
				return true
			} else {
				this.notificationService.openSnackBar(`Hiba történt az aktív bérlet törlése során (${response.status}): ${response.body}`)
				console.error(response)
				return false
			}
		} catch (error: any) {
			this.notificationService.openSnackBar('Hiba történt az aktív bérlet törlése során: ' + error.error.message)
			console.error(error)
			return false
		} finally {
			this.isActivePassLoading.set(false)
		}
	}
	//#endregion

	// MARK: Reset data
	reset() {
		this.passes.set([])
		this.activePasses.set([])
		this._passesLoaded = false
		this._activePassesLoaded = false
	}
}
